ion-content ion-toolbar {
  --background: translucent;
}

.container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.container strong {
  font-size: 20px;
  line-height: 26px;
}

.container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

.container a {
  text-decoration: none;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(375px, 1fr));
}

.grid-small {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(313px, 1fr));
}

@media (max-width: 365px) {
  .grid {
    grid-template-columns: repeat(auto-fit, 100%);
  }
  .grid-small {
    grid-template-columns: repeat(auto-fit, 100%);
  }
}

.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.md.card-title {
  padding-top: 5px;
}

.card-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.add-popover {
  --width: 100%;
}

@media (min-width: 640px) {
  .add-popover {
    --max-width: 400px;
  }
}

.login-popover {
  --width: 100%;
  --height: 100%;
  --max-width: 355px;
  --max-height: 350px;
}

.item-radius {
  --ion-item-background: var(--ion-color-light-tint);
}

.md.item-radius {
  border-radius: 4px;
}

.ios.item-radius {
  border-radius: 10px;
}

.ios.text-segment {
  margin-top: 8px;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
}

.drop-zone-active {
  border: 5px solid var(--ion-color-primary);
  transition: border 500ms;
}

.drop-zone-notactive {
  border: 5px solid transparent;
  transition: border 500ms;
}

.footer {
  width: 100%;
  height: 40px;
  text-align: center;
  margin-bottom: 10px;
  font-size: 14px;
}

.split-pane {
  --side-max-width: 280px;
}
